<template>
  <div class="violations-modal-view__header">
    <div class="violations-modal-view__header-col">
      <r-button
        type="primary"
        icon="edit"
        @click="$emit('edit')"
      >
        Редактировать
      </r-button>
      <r-delete-button
        type="danger"
        mini
        @delete="$emit('delete')"
      />
      <r-block
        no-padding
        col
      >
        <r-title type="subtitle-2">
          Устранено
        </r-title>
        <el-switch
          v-model="source.eliminated"
          disabled
          class="r-switch"
        />
      </r-block>
    </div>
    <div class="violations-modal-view__header-col">
      <r-button
        icon="copy"
        title="Создать копию"
        @click="$emit('copy')"
      />
      <r-button
        icon="upload"
        title="Экспорт"
        @click="$emit('export')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.violations-modal-view__header {
  display: flex;
  justify-content: space-between;

  &-col {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
  }
}
</style>
